import React from 'react'

const SvgSettings = props => (
  <svg width={30} height={30} {...props}>
    <path
      fill="none"
      stroke="#F5F2ED"
      strokeWidth={1.75}
      d="M25.873 11.724l-.869-2.1c.258-.694.754-2.64.84-2.98a.938.938 0 00-.252-.868l-1.369-1.36a.929.929 0 00-.868-.252c-.258.06-2.24.56-2.98.84l-2.1-.868c-.305-.672-1.333-2.394-1.52-2.69A.929.929 0 0015.963 1h-1.927a.937.937 0 00-.792.436c-.143.225-1.198 1.991-1.52 2.691l-2.1.868c-.694-.258-2.64-.753-2.98-.84a.938.938 0 00-.868.253l-1.36 1.368a.929.929 0 00-.252.869c.06.258.56 2.24.84 2.98l-.868 2.1c-.672.304-2.394 1.332-2.69 1.52a.929.929 0 00-.446.791v1.924c0 .322.165.62.436.793.225.143 1.991 1.199 2.691 1.52l.868 2.1c-.172.463-.452 1.457-.84 2.98-.046.208.038.497.253.867l1.36 1.36a.93.93 0 00.868.253c.258-.059 2.24-.56 2.98-.84l2.1.869c.305.671 1.333 2.394 1.52 2.69.17.28.473.45.8.448h1.924a.94.94 0 00.793-.437c.143-.224 1.199-1.99 1.52-2.69l2.1-.869c.695.259 2.64.754 2.98.84.313.07.64-.025.867-.252l1.36-1.36a.93.93 0 00.253-.868c-.059-.258-.56-2.24-.84-2.98l.869-2.1c.671-.305 2.394-1.333 2.69-1.52a.931.931 0 00.448-.8v-1.928a.925.925 0 00-.437-.792c-.224-.143-1.99-1.198-2.69-1.52zM14.995 21a6 6 0 11.008-12 6 6 0 01-.008 12z"
    />
  </svg>
)

export default SvgSettings
