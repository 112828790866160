import API from './api'
import { updateTeamMember, setTeamData } from './team-data'
import { setHuntData } from './hunt-data'

function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime()
  if (typeof performance !== `undefined` && typeof performance.now === `function`) {
    d += performance.now() //use high-precision timer if available
  }
  return `xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, function(c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === `x` ? r : (r & 0x3) | 0x8).toString(16)
  })
}

const isBrowser = typeof window !== `undefined`

export const getUserID = () => {
  // get or create a persistent user ID
  let userId = window.localStorage.watsonUserID
  if (!userId) {
    userId = generateUUID()
    window.localStorage.watsonUserID = userId
  }
  return userId
}

const getUser = () => (window.localStorage.watsonUser ? JSON.parse(window.localStorage.watsonUser) : {})

export const setUser = user => {
  // localStorage.setItem(`watsonUser`, JSON.stringify(user))
  window.localStorage.watsonUser = JSON.stringify(user)
  // also update the user in the team data
  return updateTeamMember(user)
}

export const updateUser = data => {
  if (!isBrowser) return new Promise(function(resolve, reject) { resolve(true) })
  let current_user = getCurrentUser()
  for (var key in data) {
    current_user[key] = data[key]
  }
  //console.log(current_user)
  return setUser(current_user)
}

export const isLoggedIn = () => {
  if (!isBrowser) return false
  const user = getUser()
  return user.guid !== undefined
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return
  console.log(`Logging out...`)
  localStorage.removeItem(`token`)
  localStorage.removeItem(`siteData`)
  localStorage.removeItem(`mmData`)
  localStorage.removeItem(`notifications`) // Clear notifications on logout
  setUser({})
  setHuntData({})
  setTeamData({})
  callback()
}
