import { getCurrentUser } from './auth'
import { getCurrentHuntData } from './hunt-data'
import API from './api'
import dayjs from 'dayjs'

const isBrowser = typeof window !== `undefined`
let is_updating = false

const getTeamData = () => (window.localStorage.teamData ? JSON.parse(window.localStorage.teamData) : {})

export const setTeamData = data => (window.localStorage.teamData = JSON.stringify(data))

export const getCurrentTeamData = () => isBrowser && getTeamData()

export const setCurrentTeamData = data => isBrowser && setTeamData(data)

export const getCurrentAccessCode = () => {
  if (!isBrowser) return false
  const data = getTeamData()
  return data.access_code ? data.access_code : ``
}

export const updateCurrentTeamData = () => {
  if (!isBrowser) return false
  const access_code = getCurrentAccessCode()
  return API.get(`session/${access_code}/`)
    .then(res => {
      //console.log(res)
      console.log(`Updating team data to local storage...`)
      setTeamData(res.data.team)
      return res.data.team
    })
    .catch(function(error) {
      console.log(error)
    })
    .finally(function(){
      is_updating = false
    })
}

export const teamAnswerQuestion = question_data => {
  if (!isBrowser) return false
  let teamData = getTeamData()
  if (teamData.answers == undefined) {
    teamData.answers = {}
  }
  teamData.answers[question_data.question_id] = question_data
  setTeamData(teamData)
  return null
}

export const teamAnswerSecondaryQuestion = question_data => {
  if (!isBrowser) return false
  let teamData = getTeamData()
  if (teamData.secondary_answers == undefined) {
    teamData.secondary_answers = {}
  }
  teamData.secondary_answers[question_data.question_id] = question_data
  setTeamData(teamData)
  return null
}

export const teamAnswerChecklist = checklist_data => {
  if (!isBrowser) return false
  let teamData = getTeamData()
  if (teamData.checklist_answers == undefined) {
    teamData.checklist_answers = {}
  }
  teamData.checklist_answers[checklist_data.checklist_id] = checklist_data
  setTeamData(teamData)
  return null
}

export const teamAnswerMysteryMessage = mm_data => {
  if (!isBrowser) return false
  let teamData = getTeamData()
  teamData.mystery_message_answers = mm_data
  setTeamData(teamData)
  return null
}

export const teamAnswerEmergencyMessage = em_data => {
  if (!isBrowser) return false
  let teamData = getTeamData()
  teamData.emergency_message_answers = em_data
  setTeamData(teamData)
  return null
}

export const teamRemovePhotoId = photo_id => {
  if (!isBrowser) return false
  let teamData = getTeamData()
  let newTeamData = getTeamData() // new copy to modify while we iterate the other one
  if (teamData.checklist_answers != null) {
    for (const checklist_id in teamData.checklist_answers) {
      const answers = teamData.checklist_answers[checklist_id]
      let total_points = answers.totalPoints
      for (const answer_id in answers.checked) {
        const answer = answers.checked[answer_id]
        if (answer.photo !== undefined && answer.photo.id == photo_id) {
          // photo is used, remove it and then subtract the points
          total_points -= answer.points
          let earned_points = total_points
          if (earned_points < 0) {
            earned_points = 0
          }
          newTeamData.checklist_answers[checklist_id].points = earned_points
          newTeamData.checklist_answers[checklist_id].totalPoints = total_points
          delete newTeamData.checklist_answers[checklist_id].checked[answer_id]
        }
      }
    }
    setTeamData(newTeamData)
    return updateServerTeam()
  }
  return null
}

export const getTeamScore = () => {
  if (!isBrowser) return 0
  let teamData = getTeamData()
  if (teamData !== undefined) {
    return teamData.score
  } else {
    return 0
  }
}

export const isPermanentCode = () => {
  if (!isBrowser) return true
  let teamData = getTeamData()
  if (teamData !== undefined) {
    return teamData.permanent
  } else {
    return true
  }
}

// gets the hunt status
// returns 'pending' for before the hunt is started, 'started' for in progress hunts, and 'ended' for hunts that are over
export const getHuntStatus = () => {
  if (!isBrowser) return false
  const teamData = getTeamData()
  if (teamData !== undefined && teamData.start_time !== null) {
    if (teamData.end_time !== null) {
      return `ended`
    } else {
      return `started`
    }
  }
  return `pending`
}

export const getTeamTotalScore = () => {
  if (!isBrowser) return 0
  let teamData = getTeamData()
  let totalScore = 0
  if (teamData !== undefined) {
    if (teamData.score !== null) {
      totalScore += teamData.score
    }

    // add in secondary_question points
    if (teamData.secondary_answers !== null) {
      for (var id in teamData.secondary_answers) {
        if(teamData.secondary_answers[id].points){
          totalScore += teamData.secondary_answers[id].points
        }
      }
    }
    // add in checklist points
    if (teamData.checklist_answers !== null) {
      for (var id in teamData.checklist_answers) {
        if(teamData.checklist_answers[id].points){
          totalScore += teamData.checklist_answers[id].points
        }
      }
    }

    // add in mystery message points
    if (teamData.mystery_message_answers !== null && teamData.mystery_message_answers !== undefined && teamData.mystery_message_answers.points !== undefined) {
      totalScore += teamData.mystery_message_answers.points
    }

    // add in emergency message points
    if (teamData.emergency_message_answers !== null && teamData.emergency_message_answers !== undefined && teamData.emergency_message_answers.points !== undefined) {
      totalScore += teamData.emergency_message_answers.points
    }

    let time_deduction = getTeamTimeDeduction()
    totalScore -= time_deduction

    if (teamData.bonus_points !== undefined){
      totalScore += teamData.bonus_points
    }
  }
  return totalScore
}

export const getTeamTimeDeduction = () => {
  if (!isBrowser) return 0
  const huntData = getCurrentHuntData()
  const teamData = getCurrentTeamData()
  if(!huntData.hunt.enable_timer) return 0 // no time deduction if timer isn't enabled
  let duration = (huntData.hunt !== undefined ? huntData.hunt.duration : 2) * 60 // in seconds
  let seconds = 0
  // deduct points for going over time
  let huntStart = teamData.start_time !== undefined ? teamData.start_time : false
  let huntEnd = teamData.end_time !== undefined ? teamData.end_time : false
  if (huntStart) {
    // hunt has started, figure out elapsed seconds from start_time to now
    let now = new Date().getTime()
    if (huntEnd) {
      now = Date.parse(huntEnd)
    }
    seconds = Math.floor((now - Date.parse(huntStart)) / 1000)
  }

  let seconds_left = duration - seconds
  if (seconds_left < 0) {
    // deduct points after a certain amount
    // default is 3 points after every 5 minutes
    var five_min_intervals = Math.floor(-seconds_left / 60 / 5)
    //console.log('Five Minute Intervals: ' + five_min_intervals)
    var pointDeduction = 3 * five_min_intervals
    //console.log('pointDeduction: ' + pointDeduction)
    return pointDeduction
  }
  return 0
}

export const getTeamCompletedQuestions = () => {
  if (!isBrowser) return []
  let completed = []
  const data = getCurrentHuntData()
  const teamData = getCurrentTeamData()
  if (data !== {} && teamData !== {}) {
    const allowedGuesses =
      data.hunt !== undefined && data.hunt.allowedGuesses !== undefined ? data.hunt.allowedGuesses : 2 //Set the number of guesses for a question
    for (var id in teamData.answers) {
      const question = teamData.answers[id]
      if (question.isCorrect) {
        completed.push(question)
      } else {
        if (question.numGuesses >= allowedGuesses) {
          completed.push(question)
        }
      }
    }
  }
  return completed
}

export const getTeamQuestionsMeta = () => {
  let meta = {
    possible_points: 0,
    num_questions: 0,
    score: 0,
    num_complete: 0,
  }
  if (!isBrowser) return meta
  const data = getCurrentHuntData()
  const teamData = getCurrentTeamData()

  let q_possible_points = 0
  if (data.questions !== undefined) {
    meta[`num_questions`] = data.questions.length
    data.questions.map(question => {
      let q_points = question.answer_value ? question.answer_value : 5
      q_possible_points += q_points
    })
    meta[`possible_points`] = q_possible_points
  }
  if(data.hunt !== undefined && data.hunt.max_question_points !== undefined && data.hunt.max_question_points > 0){
    meta[`possible_points`] = data.hunt.max_question_points
  }
  if (teamData !== {}) {
    meta[`score`] = teamData.score
    meta[`num_complete`] = getTeamCompletedQuestions().length
  }
  return meta
}

export const getTeamQuestionState = q_index => {
  let q_state = ``
  if (!isBrowser) return q_state
  const data = getCurrentHuntData()
  const team = getCurrentTeamData()
  let question = null
  if (data.questions !== undefined) {
    question = data.questions[q_index]
  }
  if (team !== {} && question !== null) {
    if (team.answers !== null && team.answers[question.id] !== undefined) {
      const answer = team.answers[question.id]
      if (answer.isCorrect) {
        if (answer.points == question.answer_value) {
          q_state = `correct`
        } else {
          q_state = `partial`
        }
      } else {
        if (answer.numGuesses >= 2) {
          q_state = `wrong`
        }
      }
    }
  }
  return q_state
}

export const getTeamCompletedSecondaryQuestions = () => {
  if (!isBrowser) return []
  let completed = []
  const data = getCurrentHuntData()
  const teamData = getCurrentTeamData()
  if (data !== {} && teamData !== {}) {
    const allowedGuesses =
      data.hunt !== undefined && data.hunt.allowedGuesses !== undefined ? data.hunt.allowedGuesses : 2 //Set the number of guesses for a question
    for (var id in teamData.secondary_answers) {
      const question = teamData.secondary_answers[id]
      if (question.isCorrect) {
        completed.push(question)
      } else {
        if (question.numGuesses >= allowedGuesses) {
          completed.push(question)
        }
      }
    }
  }
  return completed
}

export const getTeamSecondaryQuestionsMeta = () => {
  let meta = {
    possible_points: 0,
    num_questions: 0,
    score: 0,
    num_complete: 0,
  }
  if (!isBrowser) return meta
  const data = getCurrentHuntData()
  const teamData = getCurrentTeamData()

  let q_possible_points = 0
  if (data.secondary_questions !== undefined) {
    meta[`num_questions`] = data.secondary_questions.length
    data.secondary_questions.map(question => {
      let q_points = question.answer_value ? question.answer_value : 5
      q_possible_points += q_points
    })
    meta[`possible_points`] = q_possible_points
  }
  if (teamData !== {}) {
    let answers = getTeamCompletedSecondaryQuestions()
    let score = 0
    answers.map(answer => {
      score += answer.points
    })
    meta[`score`] = score
    meta[`num_complete`] = answers.length
  }
  return meta
}

export const getTeamSecondaryQuestionState = q_index => {
  let q_state = ``
  if (!isBrowser) return q_state
  const data = getCurrentHuntData()
  const team = getCurrentTeamData()
  let question = null
  if (data.secondary_questions !== undefined) {
    question = data.secondary_questions[q_index]
  }
  if (team !== {} && question !== null) {
    if (team.secondary_answers !== undefined && team.secondary_answers !== null && team.secondary_answers[question.id] !== undefined) {
      const answer = team.secondary_answers[question.id]
      if (answer.isCorrect) {
        if (answer.points == question.answer_value) {
          q_state = `correct`
        } else {
          q_state = `partial`
        }
      } else {
        if (answer.numGuesses >= 2) {
          q_state = `wrong`
        }
      }
    }
  }
  return q_state
}

export const teamAddPoints = points => {
  if (!isBrowser) return false
  const data = getCurrentHuntData()
  let teamData = getTeamData()
  if(!teamData.actual_score){
    teamData.actual_score = 0
  }
  teamData.actual_score += points
  teamData.score += points
  // check that the score hasn't gone over the maximum
  if(data.hunt.max_question_points){
    if(teamData.score > data.hunt.max_question_points){
      teamData.score = data.hunt.max_question_points
    }
  }
  setTeamData(teamData)
  return null
}

export const teamUpdateStart = () => {
  if (!isBrowser) return false
  let teamData = getTeamData()
  teamData.start_time = dayjs().format()
  setTeamData(teamData)
  return updateServerTeam()
}

export const teamUpdateEnd = () => {
  if (!isBrowser) return false
  let teamData = getTeamData()
  teamData.end_time = dayjs().format()
  setTeamData(teamData)
  return updateServerTeam()
}

export const teamUpdateName = (name, count) => {
  if (!isBrowser) return false
  //console.log(`setting team name`)
  let teamData = getTeamData()
  teamData.team_name = name
  teamData.player_count = count
  setTeamData(teamData)
  return updateServerTeam()
}

export const isTeamCaptain = () => {
  if (!isBrowser) return false
  if (isPermanentCode()) return true
  const user = getCurrentUser()
  if (user.guid == undefined) return false
  let teamData = getTeamData()
  if (teamData == undefined) return false
  return user.guid == teamData.leader
}

export const hasTeamCaptain = () => {
  if (!isBrowser) return false
  let teamData = getTeamData()
  return teamData !== undefined && teamData.leader !== undefined && teamData.leader !== null && teamData.leader !== ``
}

export const getTeamCaptain = () => {
  if (!isBrowser) return false
  let teamData = getTeamData()
  let captain = {}
  if (teamData !== undefined && teamData.leader !== undefined && teamData.leader !== null && teamData.leader !== ``) {
    captain = teamData.members[teamData.leader]
  }
  return captain
}

const updateServerTeam = () => {
  if (isPermanentCode())
    /* eslint-disable-next-line */
    return new Promise(function(resolve, reject) {
      resolve(true)
    })
  let team_data = getCurrentTeamData()
  // don't overwrite the team members
  delete team_data.members
  console.log(`updating team data to the server...`)
  return API.put(`session/${team_data.access_code}/${team_data.id}/`, team_data)
    .then(
      res => {
        //console.log(`team data updated`)
        //res.data
        //console.log(res.data)
        //setTeamData(res.data)
        // update the total score to account for bonus points for the captain
        team_data = getCurrentTeamData()
        if(team_data.bonus_points != res.data.bonus_points){
          team_data.bonus_points = res.data.bonus_points
          setTeamData(team_data)
        }
        return res.data
      }
    )
    .catch(function(error) {
      console.log(error)
      return error
    })
    .finally(function(){
      is_updating = false
    })
}

export const resetTeam = () => {
  const team_data = getCurrentTeamData()
  console.log(`resetting team data...`)
  return API.post(`session/${team_data.access_code}/${team_data.id}/reset/`)
    .then(res => {
      //console.log(res)
      setTeamData(res.data)
      return res.data
    })
    .catch(function(error) {
      console.log(error)
      return error
    })
}

export const updateTeam = () => {
  if (isPermanentCode()) return getCurrentTeamData()
  const team_data = getCurrentTeamData()
  const user = getCurrentUser()
  if(!is_updating){
    is_updating = true
    if (isTeamCaptain()) {
      // First we need to check if we're still the captain
      return API.get(`session/${team_data.access_code}/captain/`).then(res => {
        //console.log(res)
        // update the team members with the server data
        // team_data.members = res.data.members
        // setTeamData(team_data)
        if (res.data.captain == user.guid) {
          return updateServerTeam()
        } else {
          team_data.leader = res.data.captain
          setTeamData(team_data)
          return updateCurrentTeamData()
        }
      }).catch(error => {
        console.log(error)
        is_updating = false
      })
    } else {
      // if not the captain, don't push data, just pull new data
      return updateCurrentTeamData()
    }
  } else {
    return new Promise(function(resolve, reject) {
      resolve(true)
    }) 
  }
}

export const updateTeamMember = user => {
  // resolve team member data between client and server
  if (!isBrowser) return false
  // don't update if it's blank or undefined
  if (user == undefined || user.guid == undefined || !user.guid) return false
  //console.log('updating team member')

  if (isPermanentCode())
    /* eslint-disable-next-line */
    return new Promise(function(resolve, reject) {
      resolve(true)
    })
  const team_data = getCurrentTeamData()
  console.log(`updating team member to the server...`)
  return API.put(`session/${team_data.access_code}/members/`, user)
    .then(
      res => {
        //console.log(`team member data updated`)
        //console.log(res.data)
        //setTeamData(res.data)
        // update the local members
        const team_data = getCurrentTeamData()
        team_data.members = res.data.members
        setTeamData(team_data)
      }
    )
    .catch(function(error) {
      console.log(error)
      return error
    })
}

export const updateTeamCaptain = (user_guid, force = false) => {
  if (!isBrowser) return false
  return updateCurrentTeamData().then(team_data => {
    if (team_data.leader == null || team_data.leader == ``) {
      // none exists, just update it
      //console.log(`no captain set, updating captain`)
      team_data.leader = user_guid
      setTeamData(team_data)
      return updateServerTeam()
    } else {
      // a leader exists
      if (force) {
        // force update the leader anyway
        //console.log(`force updating captain to: ${user_guid}`)
        team_data.leader = user_guid
        setTeamData(team_data)
        return updateServerTeam()
      } else {
        // return an error as the leader is already set
        let existing_leader = team_data.members[team_data.leader]
        //console.log(`Captain already exists: ${existing_leader.guid}`)
        return new Promise(function(resolve, reject) {
          resolve({
            status: `error`,
            data: `Too slow Joe! ${existing_leader.name} has already signed up to be your team captain.`,
          })
        })
      }
    }
  })
}
