import React from 'react'

const SvgHamburger = props => (
  <svg width={30} height={22} {...props}>
    <path
      fill="none"
      stroke="#F5F2ED"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M0 1h33H1zm0 8h33H1zm0 8h33H1z"
    />
  </svg>
)

export default SvgHamburger
