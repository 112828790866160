/**
 * Alert component
 * 
 * Shows an alert that slides up from the bottom.
 */

import React from 'react'
import Toast from 'react-bootstrap/Toast'

export default class Alert extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { onClose, show, children, type } = this.props
    return (
      <Toast onClose={onClose} show={show} delay={3000} autohide animation={false} className={`slide-up ${type}`}>
        <Toast.Body>{children}</Toast.Body>
      </Toast>
    )
  }
}
