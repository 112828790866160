/**
 * Score Component
 * 
 * Shows the team's current score, and possible points.
 * Used in the footer component.
 */

import React from 'react'
import CountUp from 'react-countup'
import { getTeamTotalScore, getHuntStatus } from '../utils/team-data'
import { getCurrentHuntData } from '../utils/hunt-data'

export default class Score extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      points: 0,
      possiblePoints: 0,
    }
    this.updateScore = this.updateScore.bind(this)
  }

  componentDidMount() {
    let data = getCurrentHuntData()
    let points = 0
    if(data.hunt.max_question_points){
      points += data.hunt.max_question_points
    } else if (data.questions != undefined) {
      data.questions.map(question => {
        let q_points = question.answer_value ? question.answer_value : 5
        points += q_points
      })
    }
    if (data.secondary_questions != undefined) {
      data.secondary_questions.map(question => {
        let q_points = question.answer_value ? question.answer_value : 5
        points += q_points
      })
    }
    if (data.checklists !== undefined) {
      data.checklists.map(checklist => {
        points += checklist.maximum_score
      })
    }
    if (data.mystery_message !== undefined) {
      points += data.mystery_message.point_value
    }

    if (data.emergency_message !== undefined) {
      points += data.emergency_message.point_value
    }
    this.setState({
      possiblePoints: points,
      points: getTeamTotalScore(),
    })
    this.interval = setInterval(() => this.updateScore(), 500)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  updateScore() {
    let points = getTeamTotalScore()
    if (points !== this.state.points) {
      this.setState({
        points: points,
      })
    }
    let state = getHuntStatus()
    if (state == `ended`) {
      clearInterval(this.interval)
    }
  }

  render() {
    const status = getHuntStatus()
    const points_text = status == `ended` ? `Total Points` : `Points Collected`
    return (
      <div className={`text-center text-uppercase`}>
        {status == `ended` ? (
          <span className={`h5 title pt-1`}>
            <CountUp end={this.state.points} preserveValue={true} />
          </span>
        ) : (
          <span className={`h5 title pt-1`}>
            <CountUp end={this.state.points} preserveValue={true} /> / {this.state.possiblePoints}
            {` `}
          </span>
        )}
        <span className={`d-block text-small`}>{points_text}</span>
      </div>
    )
  }
}
