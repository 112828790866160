module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WatsonApp","short_name":"watson","start_url":"/","background_color":"#2B3A40","theme_color":"#2B3A40","display":"standalone","icon":"static/images/WatsonAppIcon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"84047a69f29e57c9a82d641f6f3b6754"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
