/**
 * Main App Layout
 * 
 * Controls the main layout and navs, and whether they are open or not
 */

import React from 'react'
//import Favicon from 'react-favicon'
import PropTypes from 'prop-types'
import { isLoggedIn,  } from '../utils/auth'
import { getHuntStatus } from '../utils/team-data'
import { getPhotosEnabled, getCurrentHuntData } from '../utils/hunt-data'
import StickyFooter from '../components/footer'
import Header from '../components/header'
import SEO from '../components/seo'
import Transition from '../components/transition'
import AppContext from '../utils/context'
import NavPanel from '../components/panels/nav'
import SettingsPanel from '../components/panels/settings'
import FeedbackPanel from '../components/panels/feedback'
import PhotoUpload from '../components/photos/photo-upload'
import Alert from '../components/alert'
import DoneButton from '../components/done-button'
import UploadPhotoModal from '../components/photos/upload-modal'
import ShareModal from '../components/share-modal'
import { getSiteData } from '../utils/site'
import LoadingModal from '../components/loading-modal'

//import AppLayout from './app-layout'
//Note that we need to pass location to our functional component  so we have access to it down there in <Transition/>
class AppLayout extends React.Component {
  constructor(props) {
    super(props)

    this.setNavOpen = this.setNavOpen.bind(this)
    this.toggleNav = this.toggleNav.bind(this)

    this.setSettingsOpen = this.setSettingsOpen.bind(this)
    this.toggleSettings = this.toggleSettings.bind(this)

    this.setFeedbackOpen = this.setFeedbackOpen.bind(this)
    this.toggleFeedback = this.toggleFeedback.bind(this)
    this.update = this.update.bind(this)

    this.alertClose = this.alertClose.bind(this)
    this.showAlert = this.showAlert.bind(this)

    this.toggleRefresh = this.toggleRefresh.bind(this)

    this.uploadModalClose = this.uploadModalClose.bind(this)
    this.setUploadModalShow = this.setUploadModalShow.bind(this)
    this.handleUpload = this.handleUpload.bind(this)

    this.setSettingsTooltip = this.setSettingsTooltip.bind(this)
    this.closeSettingsTooltip = this.closeSettingsTooltip.bind(this)

    this.setShareModalShow = this.setShareModalShow.bind(this)
    this.shareModalClose = this.shareModalClose.bind(this)
    this.setShareDetails = this.setShareDetails.bind(this)

    this.setLoading = this.setLoading.bind(this)
    this.setShowFinish = this.setShowFinish.bind(this)

    this.updateSiteData = this.updateSiteData.bind(this)

    this.state = {
      navOpen: false,
      setNavOpen: this.setNavOpen,
      toggleNav: this.toggleNav,

      settingsOpen: false,
      setSettingsOpen: this.setSettingsOpen,
      toggleSettings: this.toggleSettings,

      feedbackOpen: false,
      setFeedbackOpen: this.setFeedbackOpen,
      toggleFeedback: this.toggleFeedback,

      loggedIn: false,
      hunt_status: `pending`,
      enable_photos: true,

      alertShow: false,
      alertClose: this.alertClose,
      alertType: `success`,
      alertMsg: `Alert!`,
      showAlert: this.showAlert,

      uploadImage: ``,
      uploadModalShow: false,
      uploadModalClose: this.uploadModalClose,
      setUploadModalShow: this.setUploadModalShow,
      uploadCallback: () => {},

      refresh: false,
      toggleRefresh: this.toggleRefresh,

      showSettingsTooltip: false,
      setSettingsTooltip: this.setSettingsTooltip,
      closeSettingsTooltip: this.closeSettingsTooltip,

      url: `https://watsonadventures.com/`,
      text: `I just completed a Watson Adventures Scavenger Hunt!`,
      shareModalShow: false,
      shareModalClose: this.shareModalClose,
      setShareModalShow: this.setShareModalShow,

      site_data: {},
      updateSiteData: this.updateSiteData,

      isLoading: false,
      setLoading: this.setLoading,

      showFinish: true,
      setShowFinish: this.setShowFinish,
    }
  }

  componentDidMount() {
    this.update()
    getSiteData().then(data => {
      this.setState({
        site_data: data,
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location != this.props.location) {
      this.update()
    }
  }

  update() {
    this.setState({
      loggedIn: isLoggedIn(),
      hunt_status: getHuntStatus(),
      enable_photos: getPhotosEnabled(),
    })
    this.setShareDetails()
  }

  updateSiteData() {
    getSiteData(true).then(data => {
      this.setState({
        site_data: data,
      })
    })
  }

  setShareDetails() {
    const data = getCurrentHuntData()
    if (data.hunt !== undefined) {
      const state_data = {}
      if (data.hunt.share_url) {
        state_data.url = data.hunt.share_url
      }
      if (data.hunt.display_title) {
        state_data.text = `I just completed Watson Adventures ${data.hunt.display_title}!`
      }
      this.setState(state_data)
    }
  }

  uploadModalClose() {
    this.setState({ uploadModalShow: false, uploadImage: `` })
  }

  setUploadModalShow(value, image, uploadCallback = () => {}) {
    this.setState({ uploadModalShow: value, uploadImage: image, uploadCallback: uploadCallback })
  }

  shareModalClose() {
    this.setState({ shareModalShow: false })
  }

  setShareModalShow(value) {
    this.setState({ shareModalShow: value })
  }

  handleUpload(response) {
    this.state.uploadCallback(response)
    this.uploadModalClose()
  }

  showAlert(msg, type = `success`) {
    this.setState({ alertMsg: msg, alertType: type, alertShow: true })
  }

  alertClose() {
    this.setState({ alertShow: false })
  }

  setLoading(isLoading) {
    this.setState({ isLoading: isLoading })
  }

  setShowFinish(state){
    this.setState({ showFinish: state})
  }

  setNavOpen(navOpen) {
    //console.log(`setting navOpen to ${navOpen}`)
    return new Promise(resolve => this.setState({ navOpen: navOpen }, () => resolve()))
  }

  toggleNav() {
    //console.log('toggling nav')
    return new Promise(resolve => this.setState({ navOpen: !this.state.navOpen }, () => resolve()))
  }

  setSettingsOpen(settingsOpen) {
    //console.log(`setting settingsOpen to ${settingsOpen}`)
    return new Promise(resolve => this.setState({ settingsOpen: settingsOpen }, () => resolve()))
  }

  toggleSettings() {
    //console.log('toggling settings')
    return new Promise(resolve => this.setState({ settingsOpen: !this.state.settingsOpen }, () => resolve()))
  }

  setFeedbackOpen(feedbackOpen) {
    //console.log(`setting feedbackOpen to ${feedbackOpen}`)
    return new Promise(resolve => this.setState({ feedbackOpen: feedbackOpen }, () => resolve()))
  }

  toggleFeedback() {
    //console.log('toggling nav')
    return new Promise(resolve => this.setState({ feedbackOpen: !this.state.feedbackOpen }, () => resolve()))
  }

  toggleRefresh() {
    //console.log('toggling refresh')
    this.setState({ refresh: !this.state.refresh })
  }

  setSettingsTooltip(open) {
    return new Promise(resolve => this.setState({ showSettingsTooltip: open }, () => resolve()))
  }

  closeSettingsTooltip() {
    return this.setSettingsTooltip(false)
  }

  render() {
    const { children, location } = this.props
    const {
      navOpen,
      settingsOpen,
      feedbackOpen,
      hunt_status,
      alertShow,
      alertClose,
      alertType,
      alertMsg,
      uploadImage,
      uploadModalShow,
      uploadModalClose,
      url,
      text,
      shareModalShow,
      shareModalClose,
      refresh,
      isLoading,
    } = this.state
    let loggedIn = this.state.loggedIn
    let navClass = navOpen ? `nav-is-active` : ``
    let settingsClass = settingsOpen ? `settings-is-active` : ``
    let feedbackClass = feedbackOpen ? `feedback-is-active` : ``
    let footerClass = hunt_status !== `pending` ? `has-footer` : ``
    if (location.pathname.startsWith(`/gallery`) || location.pathname.startsWith('/terms')) {
      loggedIn = false
      footerClass = ``
    }
    let loggedInClass = loggedIn ? `logged-in` : ``

    return (
      <AppContext.Provider value={this.state}>
        <SEO title="Watson Adventures Scavenger Hunt" />
        <a className="sr-only" href="#main">Skip to main content</a>

        <div className={`page-wrap ${navClass} ${settingsClass} ${footerClass} ${feedbackClass} ${loggedInClass} `}>
          {loggedIn && <Header location={location} />}

          <Transition location={location}>
            <div id="main" className={`site-content container-fluid px-0`}>{children}</div>
          </Transition>

          {loggedIn && (
            <>
              {location.pathname.startsWith(`/team-gallery`) && <PhotoUpload />}
              {location.pathname == `/` && hunt_status !== `pending` && <DoneButton />}
              {hunt_status !== `pending` && <StickyFooter />}
              <NavPanel />
              <FeedbackPanel />
              <SettingsPanel refresh={refresh} />
            </>
          )}
          <UploadPhotoModal
            image={uploadImage}
            show={uploadModalShow}
            modalClose={uploadModalClose}
            handleSubmit={this.handleUpload}
          />
          <ShareModal show={shareModalShow} modalClose={shareModalClose} url={url} text={text} />
          <LoadingModal show={isLoading} />
          <Alert show={alertShow} onClose={alertClose} type={alertType}>
            {alertMsg}
          </Alert>
        </div>
      </AppContext.Provider>
    )
  }
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppLayout
