import React from 'react'

const AppContext = React.createContext({
  navOpen: false,
  setNavOpen: () => {},
  toggleNav: () => {},
  settingsOpen: false,
  setSettingsOpen: () => {},
  toggleSettings: () => {},
  feedbackOpen: false,
  setFeedbackOpen: () => {},
  toggleFeedback: () => {},
  toggleFullscreen: () => {},
  site_data: {},
  isLoading: false,
  setLoading: () => {},
  showFinish: true,
  setShowFinish: () => {},
})

export default AppContext
