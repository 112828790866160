/**
 * Countdown Timer Component
 * 
 * Counts down from a particular time, down to zero and then below. Used in timed games.
 */
import React from 'react'
import { getHuntStatus, getCurrentTeamData } from '../utils/team-data'
import { getCurrentHuntData } from '../utils/hunt-data'

class CountDownTimer extends React.Component {
  constructor() {
    super()
    this.state = {
      huntData: getCurrentHuntData(),
      teamData: getCurrentTeamData(),
      elapsedSeconds: 0,
      duration: 2,
    }
    this.timer = 0
    this.tick = this.tick.bind(this)
    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.durationSeconds = this.durationSeconds.bind(this)
    this.secondsLeft = this.secondsLeft.bind(this)
  }

  componentDidMount() {
    this.updateFromData()
  }

  componentWillUnmount() {
    this.stopTimer()
  }

  componentDidUpdate() {
    if (getHuntStatus() == `started`) {
      this.startTimer()
    } else {
      this.stopTimer()
    }
  }

  updateFromData() {
    const {huntData, teamData} = this.state
    let huntDuration = huntData.hunt !== undefined ? huntData.hunt.duration : 2 // in minutes
    let state_obj = {
      duration: huntDuration,
    }
    let huntStart = teamData !== undefined ? teamData.start_time : false
    let huntEnd = teamData !== undefined ? teamData.end_time : false
    if (huntStart) {
      // hunt has started, figure out elapsed seconds from start_time to now
      let now = new Date().getTime()
      if (huntEnd) {
        now = Date.parse(huntEnd)
      }
      state_obj[`start`] = Date.parse(huntStart)
      let seconds = Math.floor((now - Date.parse(huntStart)) / 1000)
      state_obj.elapsedSeconds = seconds
    }
    this.setState(state_obj)

    if (huntStart && !huntEnd && !this.timer) {
      this.startTimer()
    }
  }

  durationSeconds() {
    return this.state.duration * 60
  }

  secondsLeft() {
    return this.durationSeconds() - this.state.elapsedSeconds
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / 3600)
    let minutes = Math.floor(secs / 60) % 60
    let seconds = Math.ceil(secs % 60)

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    }
    return obj
  }

  displayTime() {
    let secs = this.secondsLeft()
    let is_negative = false
    if (secs < 0) {
      is_negative = true
      secs = -secs
    }

    let time_obj = this.secondsToTime(secs)
    let hours = time_obj.h.toLocaleString(`en-US`, { minimumIntegerDigits: 2, useGrouping: false })
    let minutes = time_obj.m.toLocaleString(`en-US`, { minimumIntegerDigits: 2, useGrouping: false })
    let seconds = time_obj.s.toLocaleString(`en-US`, { minimumIntegerDigits: 2, useGrouping: false })
    let return_string = `${hours}:${minutes}:${seconds}`
    if (is_negative) {
      return_string = `-` + return_string
    }
    return return_string
  }

  startTimer() {
    if (!this.timer) {
      this.timer = setInterval(this.tick, 1000)
    }
  }

  stopTimer() {
    clearInterval(this.timer)
  }

  tick() {
    // count up one second at a time
    let now = new Date().getTime()
    let seconds = Math.floor((now - this.state.start) / 1000)
    this.setState({
      elapsedSeconds: seconds,
    })
  }

  render() {
    return (
      <div className={`text-uppercase text-center`}>
        <span className={`h5 title`}>{this.displayTime()}</span>
        <span className={`d-block text-small`}>Time Left</span>
      </div>
    )
  }
}

CountDownTimer.defaultProps = {
  run: false,
}

export default CountDownTimer
