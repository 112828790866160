import React from 'react'
import PropTypes from 'prop-types'

const SVGLoader = props => (
  <svg
    width={props.width || 30}
    height={props.height || 30}
    viewBox="0 0 39.952 39.952"
    className={`watson-spinner ${props.className}`}
  >
    <circle
      className="spinnerMask"
      cx="19.976"
      cy="19.976"
      r="19.05"
      strokeWidth={1.85208333}
      stroke={`rgba(255, 255, 255, 0.5)`}
      fill="none"
    />
    <path
      className="spinnerPath"
      d="M19.976.926C9.455.926.926 9.455.926 19.976s8.529 19.05 19.05 19.05 19.05-8.529 19.05-19.05"
      strokeWidth={1.85208333}
      stroke={`#fff`}
      fill="none"
    />
  </svg>
)

SVGLoader.defaultProps = {
  width: 30,
  height: 30,
  className: ``,
}

SVGLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
}

export default SVGLoader
