/**
 * Host Photo Upload Button
 * 
 * Button to allow Hosts to add a photo to the hunt.
 */
import React, { useContext } from 'react'
import Fade from 'react-reveal/Fade'
import HostContext from '../../../utils/host-context'

const PhotoUpload = () => {
  const context = useContext(HostContext)
  const handleFileUpdate = (event) => {
    context.setUploadModalShow(true, event.target.files[0])
  }
  return (
    <div className={`fixed-photo-upload`}>
      <Fade up big delay={300}>
        <div>
          <label className={`file-input-label btn btn-block btn-success mb-0`}>
            <input
              type="file"
              name="image"
              accept="image/png, image/jpeg"
              className={`form-control-file`}
              //value={this.state.image}
              onChange={handleFileUpdate}
              placeholder="Base64 Image"
              required
            />
            Add Photo
          </label>
        </div>
      </Fade>
    </div>
  )
}

export default PhotoUpload
