import React from 'react'

const SvgCamera = props => (
  <svg width={38} height={27} {...props}>
    <path
      fill="#F5F2ED"
      fillRule="evenodd"
      stroke="#F5F2ED"
      strokeWidth={0.65}
      d="M4.678 2.619h-1.37C2.038 2.619 1 3.583 1 4.769v19.08C1 25.035 2.038 26 3.308 26h31.384c1.27 0 2.308-.965 2.308-2.151V4.769c0-1.186-1.038-2.15-2.308-2.15h-24.48c-.107-.435.14-1.624-.784-1.619h-3.9c-.836-.005-.711 1.267-.85 1.619zm-1.37.864h31.384c.777 0 1.385.567 1.385 1.287v19.08c0 .717-.608 1.285-1.385 1.285H3.308c-.777 0-1.385-.567-1.385-1.286V4.77c0-.717.608-1.286 1.385-1.286zM19 6.068c-5.093 0-9.23 3.882-9.23 8.66 0 4.777 4.137 8.659 9.23 8.659 5.093 0 9.23-3.882 9.23-8.66 0-4.777-4.137-8.66-9.23-8.66zm11.034.473c.023.227.263.413.504.393h2.308c.243.004.469-.205.469-.433 0-.229-.224-.437-.469-.433h-2.308c-.504.04-.504.474-.504.474zM19 6.934c4.594 0 8.308 3.485 8.308 7.793 0 4.31-3.714 7.793-8.308 7.793-4.594 0-8.308-3.484-8.308-7.793 0-4.31 3.714-7.793 8.308-7.793zm0 3.464c2.555 0 4.615 1.933 4.615 4.331 0 2.396-2.06 4.33-4.615 4.33s-4.615-1.932-4.615-4.33c0-2.396 2.06-4.33 4.615-4.33zm0 .863c-2.04 0-3.692 1.553-3.692 3.466 0 1.916 1.653 3.466 3.692 3.466 2.04 0 3.692-1.552 3.692-3.466 0-1.915-1.653-3.466-3.692-3.466z"
    />
  </svg>
)

export default SvgCamera
