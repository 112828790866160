/**
 * Logout Modal
 * 
 * Displays the "are you sure you want to logout" message before a user
 * actually navigates to the logout page.
 */

import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { navigate } from 'gatsby'
import { logout } from '../utils/auth'
import AppContext from '../utils/context'

class Logout extends React.Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      modalShow: false,
    }
  }

  handleSubmit(event, setNavOpen) {
    event.preventDefault()
    setNavOpen(false)
    logout(() => navigate(`/login`))
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false })

    return (
      <AppContext.Consumer>
        {({ setNavOpen }) => (
          <>
            <button className={`btn btn-link px-0 py-0`} onClick={() => this.setState({ modalShow: true })}>
              Logout
            </button>

            <Modal show={this.state.modalShow} onHide={modalClose} centered aria-labelledby="logout-modal-title">
              <Modal.Header closeButton>
                <Modal.Title id="logout-modal-title">Logout</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Any previous save data will be lost. Are you sure you want to logout?</p>
                <form
                  className={`form-group`}
                  method="post"
                  onSubmit={event => {
                    this.handleSubmit(event, setNavOpen)
                  }}>
                  <input className={`btn btn-block btn-success text-uppercase`} type="submit" value=" yes, logout" />
                </form>
                <button className={`btn btn-block btn-tiny mt-4`} onClick={modalClose}>
                  Cancel
                </button>
              </Modal.Body>
            </Modal>
          </>
        )}
      </AppContext.Consumer>
    )
  }
}
export default Logout
