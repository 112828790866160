/**
 * Image Component
 * 
 * Wrapper for graphql and Gatsby's Img component
 * Used for the static illustrations shown around the app
 */

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => n.node.relativePath.includes(props.filename))
      if (!image) {
        return null
      }
      //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      return (
        <Img
          alt={props.alt}
          className={`image-wrapper`}
          fluid={image.node.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
        />
      )
    }}
  />
)

export default Image
