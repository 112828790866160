/**
 * Main Layout File
 * 
 * Chooses which Layout, the App or Host Layout, gets displayed.
 */

import React from 'react'
import PropTypes from 'prop-types'
//import Favicon from 'react-favicon'
import AppLayout from './app-layout'
import HostLayout from './host-layout'
//Note that we need to pass location to our functional component  so we have access to it down there in <Transition/>
class Layout extends React.Component {
  render() {
    const { children, location } = this.props
    let LayoutElem = AppLayout
    if (location.pathname.startsWith(`/host`)) {
      LayoutElem = HostLayout
    }
    return (
      <>
        <LayoutElem {...this.props} showPhotoPanel={this.props.showPhotoPanel} />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
