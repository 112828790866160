/**
 * App header
 * 
 * Shows the toggles for the menu, settings, and adding photos in the top
 * navigation.
 */

import React from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import SvgHamburger from './svg/hamburger'
import SvgSettings from './svg/settings'
import SvgCamera from './svg/camera'
import SvgPhotoGallery from './svg/gallery'
import SvgNotification from './svg/notification'
import AppContext from '../utils/context'
import { isPermanentCode } from '../utils/team-data'
import { navigate } from 'gatsby'
import Link from './link'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.handleFileUpdate = this.handleFileUpdate.bind(this)
    this.popoverTarget = React.createRef()
  }

  handleFileUpdate(event) {
    this.setUploadModalShow(true, event.target.files[0], () => navigate(`/team-gallery`))
  }

  render() {
    /* eslint-disable-next-line */
    const { location } = this.props
    const is_permanent = isPermanentCode()
    let cameraButton = (
      <label className={`file-input-label btn btn-link mb-0`}>
        <input
          type="file"
          name="image"
          accept="image/png, image/jpeg"
          className={`form-control-file`}
          //value={this.state.image}
          onChange={this.handleFileUpdate}
          placeholder="Base64 Image"
          required
        />
        <SvgCamera />
      </label>
    )
    return (
      <AppContext.Consumer>
        {({
          toggleNav,
          toggleSettings,
          hunt_status,
          setUploadModalShow,
          enable_photos,
          showSettingsTooltip,
          closeSettingsTooltip,
        }) => {
          this.setUploadModalShow = setUploadModalShow
          return (
            <header className={`site-header navbar navbar-expand navbar-dark fixed-top py-1`}>
              <button
                className={`btn btn-link menu-toggle d-flex align-items-center`}
                onClick={toggleNav}
                title="Menu"
                aria-haspopup="true">
                <SvgHamburger />
                <span className={`text-uppercase title h5 pl-2 mb-0`}>Menu</span>
              </button>
              <ul className="navbar-nav ml-auto">
                <SvgNotification />

                {hunt_status !== `ended` && !is_permanent && (
                  <li className="nav-item px-1">
                    <button
                      ref={this.popoverTarget}
                      className={`btn btn-link menu-toggle`}
                      onClick={toggleSettings}
                      title="Settings"
                      aria-haspopup="true">
                      <SvgSettings />
                    </button>
                    <Overlay show={showSettingsTooltip} target={this.popoverTarget.current} placement="bottom">
                      <Popover id="settings-popover">
                        <Popover.Title as="h3">
                          Settings Tip{` `}
                          <button className="close" onClick={closeSettingsTooltip} title="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                              <g fill="none" fillRule="evenodd" stroke="#273A41" strokeLinecap="round" strokeWidth="2">
                                <path d="M1.5 1.5l16.867 16.867M18.5 1.5L1.633 18.367" />
                              </g>
                            </svg>
                          </button>
                        </Popover.Title>
                        <Popover.Content>
                          <p>
                            To <strong>become the captain</strong> click on the settings gear to take over.
                          </p>
                          <p>
                            If you are already the captain, <strong>change your team name</strong> here.
                          </p>
                        </Popover.Content>
                      </Popover>
                    </Overlay>
                  </li>
                )}
                {!is_permanent && enable_photos && hunt_status != `ended` && hunt_status != `pending` && (
                  <li className="nav-item px-1">
                    <Link to={`/team-gallery/`} className={`btn btn-link`}>
                      <SvgPhotoGallery />
                      {/* <span className="d-block">Gallery</span> */}
                    </Link>
                  </li>
                )}
                {hunt_status != `ended` && hunt_status != `pending` && enable_photos && (
                  <li className="nav-item px-1">{cameraButton}</li>
                )}
              </ul>
            </header>
          )
        }}
      </AppContext.Consumer>
    )
  }
}
Header.contextType = AppContext
export default Header
