/**
 * Loading Modal Component
 * 
 * Overlay modal that shows the loading spinner
 */

import React from 'react'
import Modal from 'react-bootstrap/Modal'
import SVGLoader from './svg/loader'

export default class LoadingModal extends React.Component {
  render() {
    const { show } = this.props
    return (
      <Modal show={show} onHide={() => {}} centered aria-labelledby="loading-modal-title">
        <Modal.Header>
          <Modal.Title id="loading-modal-title">Loading...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`text-center mt-4`}>
            <SVGLoader width={60} height={60} />
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
