/**
 * Feedback Panel Component
 * 
 * Panel that opens when feedback should be submitted. Handles feedback in the
 * middle of the game, for users reporting issues
 */

import React from 'react'
import API from '../../utils/api'
import { getCurrentUser } from '../../utils/auth'
import AppContext from '../../utils/context'
import Image from '../image'
import Modal from 'react-bootstrap/Modal'
import SVGLoader from '../svg/loader'
import { getCurrentHuntData } from '../../utils/hunt-data'

class FeedbackPanel extends React.Component {
  constructor(props) {
    super(props)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.modalClose = this.modalClose.bind(this)
    this.state = {
      feedback_type: ``,
      comment: ``,
      modalShow: false,
      loading: false,
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({ loading: true })
    let hunt_data = getCurrentHuntData()
    const { name } = getCurrentUser()

    const self = this

    const post_data = new FormData()
    post_data.append(`feedback_type`, this.state.feedback_type)
    post_data.append(`comment`, this.state.comment)
    post_data.append(`hunt_id`, hunt_data.hunt.id)
    post_data.append(`name`, name)

    API.post(`feedback/`, post_data)
      .then(res => {
        console.log(res)
        this.setState({
          modalShow: true,
          loading: false,
          feedback_type: ``,
          comment: ``,
        })
      })
      .catch(function(error) {
        console.log(error)
        self.setState({ loading: false })
      })
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  modalClose() {
    this.setState({ modalShow: false })
    this.toggleFeedback()
  }

  render() {
    const { loading } = this.state
    return (
      <AppContext.Consumer>
        {({ feedbackOpen, toggleFeedback }) => {
          this.toggleFeedback = toggleFeedback
          return (
            <div className="site-menu site-menu-feedback">
              <div className={`px-4 mt-4`}>
                <div className={`row no-gutters`}>
                  <div className={`col-4`}>
                    <button
                      className={`px-4 py-3 btn btn-link menu-toggle ${feedbackOpen ? `feedback-is-active` : ``}`}
                      onClick={toggleFeedback} title="Close Feedback">
                      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="21" viewBox="0 0 29 21">
                        <path
                          fill="#F5F2ED"
                          d="M36.1789135,16.5797925 C36.0826967,16.9180065 36.1610449,17.293792 36.3768432,17.531238 L43.4804971,25.6345728 L17.6714585,25.6345728 C17.3030805,25.6567786 16.982839,26.0923668 17.0007142,26.5501608 C17.0185895,27.0079549 17.3690922,27.4059283 17.7374702,27.3837142 L43.4807082,27.3837142 L36.3770543,35.4734931 C36.238229,35.628935 36.1543842,35.8475776 36.1433882,36.0798767 C36.1337665,36.3121846 36.1997445,36.5393762 36.324822,36.7118852 C36.4512788,36.8861172 36.6272164,36.9886038 36.8141394,36.9988538 C37.0010731,37.0108109 37.1838898,36.9288181 37.3227045,36.7716709 L45.7677062,27.1513932 C45.9147794,26.9857014 46,26.7499784 46,26.5022868 C46,26.2545953 45.9147794,26.0188679 45.7677062,25.8531805 L37.3227045,16.2329028 C37.185255,16.0757512 37.0038177,15.9920547 36.8168841,16.0005949 C36.5280404,16.0144044 36.2764884,16.243297 36.1789135,16.5797925 Z"
                          transform="rotate(-180 23 18.5)"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div className={`mx-5`}>
                <div className={`row mt-1 mb-4 align-items-center`}>
                  <div className={`col-5`}>
                    <div
                      style={{
                        maxWidth: `150px`,
                      }}>
                      <Image alt="Site Feedback" filename="hunt_ladies.png" />
                    </div>
                  </div>
                  <div className={`col-7 pr-0`}>
                    <h6 className={`title h5 text-uppercase`}>Let us know what you think!</h6>
                    <p>Issues on a question? Love every part of the hunt? Let us know!</p>
                  </div>
                </div>
                <form
                  method="post"
                  onSubmit={event => {
                    this.handleSubmit(event)
                  }}>
                  <div className={`form-group mb-3`}>
                    <label className={`sr-only`} htmlFor="feedback_type">
                      Type of Feedback
                    </label>

                    <div className="select-wrapper">
                      <select
                        name="feedback_type"
                        className={`form-control px-2`}
                        value={this.state.feedback_type}
                        onChange={this.handleUpdate}>
                        <option value="1">Comment/Feedback</option>
                        <option value="2">Report a bug</option>
                      </select>
                      <svg
                        className="down-arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="9"
                        viewBox="0 0 19 9">
                        <path
                          fill="#273A41"
                          d="M18.344.858a.483.483 0 0 0 0-.71.554.554 0 0 0-.753 0L9.5 7.785 1.409.147a.554.554 0 0 0-.753 0 .486.486 0 0 0 0 .71l8.468 7.995a.553.553 0 0 0 .752 0L18.344.858z"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className={`form-group border-bottom-0`}>
                    <label className={`sr-only`} htmlFor="comment">
                      comment
                    </label>
                    <textarea
                      type="text"
                      rows="5"
                      name="comment"
                      className={`form-control form-control-textarea px-4 py-3`}
                      value={this.state.comment}
                      onChange={this.handleUpdate}
                      placeholder="Let us know what question you had an issue with and any additional information that would help us fix it and make this the best experience it can be."
                    />
                  </div>
                  <div className="mx-4">
                    {loading ? (
                      <p className={`text-center pt-4`}>
                        <SVGLoader className={`text-primary`} />
                      </p>
                    ) : (
                      <input className={`btn btn-block btn-primary`} type="submit" value="Submit" />
                    )}
                  </div>
                </form>
                <div className={`text-center mt-4`}>
                  <button className={`btn btn-tiny btn-cancel`} onClick={toggleFeedback}>
                    Cancel
                  </button>
                </div>
              </div>
              <Modal
                show={this.state.modalShow}
                onHide={this.modalClose}
                centered
                dialogClassName={`feedbackDialog`}
                backdropClassName={`feedback-overlay`}>
                <Modal.Header>
                  <button className={`btn btn-link`} onClick={this.modalClose} title="Close">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                      <g fill="none" fillRule="evenodd" stroke="#F5F2ED" strokeLinecap="round" strokeWidth="2">
                        <path d="M1.5 1.5l16.867 16.867M18.5 1.5L1.633 18.367" />
                      </g>
                    </svg>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <div
                    style={{
                      maxWidth: `400px`,
                    }}>
                    <div style={{ maxWidth: `225px` }} className={`mb-4 text-center mx-auto`}>
                      <Image alt="Hands claping" filename="hands_clap.png" />
                    </div>
                    <p
                      className={`mb-0`}
                      style={{
                        fontFamily: `adelle,serif`,
                        fontStyle: `italic`,
                        fontSize: `13px`,
                        color: `#273A41`,
                      }}>
                      You’re making Watson Adventures better!
                    </p>

                    <p
                      style={{
                        fontFamily: `"ff-good-web-pro-extra-conden", GoodPro-XCondBlack, sans-serif`,
                        textTransform: `uppercase`,
                        fontWeight: `800`,
                        fontSize: `23px`,
                        color: `#273A41`,
                        letterSpacing: `2.04px`,
                        textAlign: `right`,
                        lineHeight: `28px`,
                      }}>
                      Thanks for your input
                    </p>

                    <div className={`text-center mt-5`}>
                      <button className={`btn btn-tiny`} onClick={this.modalClose}>
                        Dismiss
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          )
        }}
      </AppContext.Consumer>
    )
  }
}
export default FeedbackPanel
