/**
 * Link Component
 * 
 * Wrapper component around Gatsby's navigate function.
 * Closes the nav panel before going to the next page.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import AppContext from '../utils/context'

// TODO: this is how gatsby link works - maybe copy how it does it for active link styling
// https://github.com/gatsbyjs/gatsby/blob/561d33e2e491d3971cb2a404eec9705a5a493602/packages/gatsby-link/src/index.js

class Link extends React.Component {
  constructor(props) {
    super(props)
    this.go = this.go.bind(this)
  }

  go(event) {
    event.preventDefault()
    const { to } = this.props
    if (window.location.pathname + window.location.hash != to) {
      this.setLoading(true)
      this.setNavOpen(false).then(() => {
        navigate(to)
      })
    } else {
      this.setNavOpen(false)
    }
  }

  render() {
    const { to, children } = this.props
    return (
      <AppContext.Consumer>
        {({ setNavOpen, setLoading }) => {
          this.setLoading = setLoading
          this.setNavOpen = setNavOpen
          return (
            <a
              href={to}
              onClick={event => {
                this.go(event)
              }}
              {...this.props}>
              {children}
            </a>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

Link.propTypes = {
  to: PropTypes.string,
}

export default Link
