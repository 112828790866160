/**
 * Feedback Panel Toggle Button
 * 
 * Shown in the navigation, opens the feedback panel.
 */

import React from 'react'
import AppContext from '../utils/context'

class Feedback extends React.Component {
  render() {
    return (
      <AppContext.Consumer>
        {({ toggleFeedback }) => (
          <button className={`btn btn-link px-0 py-0`} onClick={toggleFeedback}>
            Submit Feedback
          </button>
        )}
      </AppContext.Consumer>
    )
  }
}
export default Feedback
