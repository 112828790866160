/**
 * Navigation Panel Component
 * 
 * Main navigation that opens when the hamburger menu is pressed.
 * Allows users to navigate to different sections of the hunt without
 * going back to the main overview page.
 */

import React from 'react'
import Link from '../link'
import { getCurrentUser } from '../../utils/auth'
import Feedback from '../feedback'
import Logout from '../logout'
import { getHuntStatus, isPermanentCode } from '../../utils/team-data'
import { getSiteData } from '../../utils/site'
import AppContext from '../../utils/context'
import { getCurrentHuntData } from '../../utils/hunt-data'

class NavPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      read_rules: false,
      user: undefined,
      hunt_state: undefined,
    }
    this.update = this.update.bind(this)
    this.escFunction = this.escFunction.bind(this)
  }

  escFunction(event){
    if(event.keyCode === 27) {
      //Do whatever when esc is pressed
      this.toggleNav()
    }
  }

  componentDidMount() {
    this.update()
    this.interval = setInterval(this.update, 1 * 1000)
    document.addEventListener(`keydown`, this.escFunction, false)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    document.removeEventListener(`keydown`, this.escFunction, false)
  }

  update() {
    this.setState({
      hunt_state: getHuntStatus(),
      data: getCurrentHuntData(),
      user: getCurrentUser(),
    })
  }

  render() {
    const { data, hunt_state } = this.state
    const is_permanent = isPermanentCode()
    const show_questions = hunt_state == `started`
    const hunt_type =
      data.hunt !== undefined && data.hunt.hunt_type !== undefined
        ? data.hunt.hunt_type
        : 0
    const isGnG = hunt_type == 1
    const show_leaderboard = data.hunt !== undefined && data.hunt.enable_leaderboard !== undefined ? data.hunt.enable_leaderboard : false
    const show_contact = data.hunt !== undefined && data.hunt.enable_contact_page !== undefined ? (data.hunt.enable_contact_page && hunt_state !== `ended`) : false
    return (
      <AppContext.Consumer>
        {({ navOpen, toggleNav, enable_photos, site_data }) => {
          this.toggleNav = toggleNav
          return (
            <div className="site-menu site-menu-nav" role="menu" aria-expanded={`${navOpen ? `true` : `false`}`}>
              <nav>
                <div className={`px-4 mt-4`}>
                  <button
                    className={`px-4 py-3 btn btn-link menu-toggle ${navOpen ? `nav-is-active` : ``}`}
                    onClick={toggleNav} title="Close Menu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                      <g fill="none" fillRule="evenodd" stroke="#273A41" strokeLinecap="round" strokeWidth="2">
                        <path d="M1.5 1.5l16.867 16.867M18.5 1.5L1.633 18.367" />
                      </g>
                    </svg>
                  </button>
                </div>
                <ul className={`list-unstyled mt-2 px-5 list-menu`}>
                  {hunt_state !== `ended` && (
                    <li>
                      <Link to="/">Your Game</Link>
                    </li>
                  )}

                  {hunt_state !== `ended` && (
                    <li>
                      <Link to="/rules/">How to Play</Link>
                    </li>
                  )}

                  {show_questions && (
                    <>
                      {data.questions !== undefined && data.questions.length > 0 && (
                        <li>
                          <Link to={isGnG ? `/questions/overview/` : `/questions/`}>{data.hunt.questions_title ? data.hunt.questions_title : `Questions`}</Link>
                        </li>
                      )}
                      {data.secondary_questions !== undefined && data.secondary_questions.length > 0 && (
                        <li>
                          <Link to={`/questions/final/`}>{data.hunt.secondary_questions_title ? data.hunt.secondary_questions_title : `Final Questions`}</Link>
                        </li>
                      )}
                      {data.hunt.enable_answer_sheet !== undefined &&
                      data.hunt.enable_answer_sheet && (
                        <li>
                          <Link to={`/answers/`}>
                            {data.hunt.answer_sheet_title
                              ? data.hunt.answer_sheet_title
                              : `Team Answer Sheet`}
                          </Link>
                        </li>
                      )}
                    </>
                  )}
                  {show_questions && data.checklists !== undefined && data.checklists.length > 0 && (
                    <>
                      {data.checklists.map(checklist => (
                        <li key={checklist.id}>
                          <Link to={`/checklists/#${checklist.id}`}>{checklist.display_title}</Link>
                        </li>
                      ))}
                    </>
                  )}
                  {!is_permanent && enable_photos && hunt_state != `pending` && (
                    <li>
                      <Link to="/team-gallery/">Team Photo Gallery</Link>
                    </li>
                  )}
                  {show_questions && (
                    <>
                      {data.hunt.enable_bonus_challenge !== undefined &&
                      data.hunt.enable_bonus_challenge && (
                        <li>
                          <Link to={`/bonus-challenge/`}>
                            {data.hunt.bonus_challenge_title
                              ? data.hunt.bonus_challenge_title
                              : `Bonus Challenge`}
                          </Link>
                        </li>
                      )}
                      {data.mystery_message !== undefined && data.mystery_message !== null && (
                        <li>
                          <Link to={`/mystery-message/`}>Mystery Message</Link>
                        </li>
                      )}
                      {data.emergency_message !== undefined && data.emergency_message !== null && (
                        <li>
                          <Link to={`/emergency-message/`}>Emergency Message</Link>
                        </li>
                      )}
                    </>
                  )}

                  {/* <li>
                  <Feedback />
                </li> */}
                  
                  {show_leaderboard && (
                    <li>
                      <Link to="/leaderboard/">Leaderboard</Link>
                    </li>
                  )}
                  {show_contact && (
                    <li>
                      <Link to={`/contact/`}>
                        {data.hunt.contact_page_title
                          ? data.hunt.contact_page_title
                          : `Contact Host`}
                      </Link>
                    </li>
                  )}
                  {show_questions && (
                    <li>
                      <Link to="/finish/">Done?</Link>
                    </li>
                  )}
                  {hunt_state == `ended` && (
                    <li>
                      <Link to="/finish/">Results</Link>
                    </li>
                  )}
                  <li>
                    <Logout />
                  </li>
                </ul>
              </nav>

              <div
                className={`copyright py-1 text-center text-uppercase`}>
                <div dangerouslySetInnerHTML={{ __html: site_data.copyright_text }} />
                {site_data.terms_headline && 
                <small><a className="d-block text-white" href="/terms" target="_blank" rel="noopener noreferer">{site_data.terms_headline}</a></small>
                }
              </div>
            
            </div>
          )}
        }
      </AppContext.Consumer>
    )
  }
}
export default NavPanel
