/**
 * Photo Upload Component
 * 
 * Add Photo button that brings up the modal that allows users to upload photos
 */

import React from 'react'
import Fade from 'react-reveal/Fade'
import AppContext from '../../utils/context'

export default class PhotoUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      image: ``,
    }
    this.handleFileUpdate = this.handleFileUpdate.bind(this)
  }

  handleFileUpdate(event) {
    this.setUploadModalShow(true, event.target.files[0])
  }

  render() {
    return (
      <AppContext.Consumer>
        {({ setUploadModalShow }) => {
          this.setUploadModalShow = setUploadModalShow
          return (
            <div className={`fixed-photo-upload`}>
              <Fade up big delay={300}>
                <div>
                  <label className={`file-input-label btn btn-block btn-success mb-0`}>
                    <input
                      type="file"
                      name="image"
                      accept="image/png, image/jpeg"
                      className={`form-control-file`}
                      //value={this.state.image}
                      onChange={this.handleFileUpdate}
                      placeholder="Base64 Image"
                      required
                    />
                    Add Photo
                  </label>
                </div>
              </Fade>
            </div>
          )
        }}
      </AppContext.Consumer>
    )
  }
}
