import API from './api'

const isBrowser = typeof window !== `undefined`
export const setSiteData = data => (window.localStorage.siteData = JSON.stringify(data))
export const getLocalSiteData = () =>
  isBrowser && window.localStorage.siteData ? JSON.parse(window.localStorage.siteData) : {}

// Remove site data from hunt api after complete
export const getSiteData = (force = false) => {
  if (!isBrowser) return {}
  let siteData = getLocalSiteData()
  //console.log(siteData)
  if (!force && siteData !== null && siteData !== undefined && Object.getOwnPropertyNames(siteData).length !== 0) {
    // eslint-disable-next-line
    return new Promise(function(resolve, reject) {
      resolve(siteData)
    })
  } else {
    console.log(`fetching site data`)
    return API.get(`current_site/`)
      .then(res => {
        //console.log(res)
        console.log(`Updating local storage with global site settings...`)
        setSiteData(res.data)
        return res.data
      })
      .catch(function(error) {
        console.log(error)
      })
  }
}
