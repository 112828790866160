import React from 'react'

const SvgPhotoGallery = props => (
  <svg width={34} height={26} {...props}>
    <g fill="none" fillRule="evenodd" stroke="#F5F2ED" transform="translate(1 1)">
      <path
        fill="#F5F2ED"
        strokeWidth=".86"
        d="M0.422563572,24.0800004 L31.397437,24.0800004 C31.6076897,24.0800004 31.8200005,23.9205855 31.8200005,23.6559701 L31.8200005,0.423797422 C31.8200005,0.212930711 31.6612819,0 31.397437,0 L0.422563572,0 C0.212310781,0 0,0.159182033 0,0.423797422 L0,23.7099517 C0,23.9208184 0.212310781,24.0800004 0.422563572,24.0800004 L0.422563572,24.0800004 Z M0.791534951,23.2859214 L0.791534951,18.4174216 L30.9748734,18.4174216 L30.9748734,23.2859214 L0.791534951,23.2859214 Z M8.76070884,17.6236813 L10.2901654,15.0312972 C10.5004182,14.6612485 10.8714687,14.4482966 11.2919321,14.4482966 C11.7144956,14.4482966 12.083467,14.6591633 12.2936987,15.0312972 L13.0316204,16.3006043 C13.0852125,16.4060377 13.2418731,16.511471 13.3490575,16.511471 C13.4541839,16.511471 13.6129025,16.4577224 13.6664947,16.3006043 L17.5705562,9.47458576 C17.780809,9.10453701 18.1518594,8.89158513 18.5723228,8.89158513 C18.9948864,8.89158513 19.3638577,9.10245184 19.5740894,9.47458576 L24.3274151,17.6236813 L8.76070884,17.6236813 Z M30.9775118,0.847594845 L30.9775118,17.6776628 L25.2246358,17.6776628 L20.316697,9.10465344 C19.9477256,8.46999989 19.3149303,8.15161466 18.6285112,8.15161466 C17.9420921,8.15161466 17.3092863,8.5216634 16.9403255,9.10465344 L13.3516116,15.2961243 L12.9826402,14.6077009 C12.6136688,13.9730474 11.9808735,13.6546621 11.2944544,13.6546621 C10.6080353,13.6546621 9.97522951,14.0247109 9.60626869,14.6077009 L7.86446964,17.623893 L0.792368701,17.623893 L0.792368701,0.847806537 L30.9775118,0.847594845 Z"
      />
      <circle cx="7" cy="7" r="2.25" strokeWidth="1.5" />
    </g>
  </svg>
)

export default SvgPhotoGallery
