/**
 * Floating Input Component
 * 
 * An input field where the placeholder/label is shown in the central area
 * then "floats" down below the input line. Used in many places around the app,
 * almost every text input field.
 */
import React from 'react'

class FloatingInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      focus: false,
      filled: false,
    }
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onFocus = this.onFocus.bind(this)
  }

  componentDidMount() {
    if (this.props.value.toString().length) {
      this.setState({ filled: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value != this.props.value) {
      this.setState({ filled: this.props.value.toString().length > 0 })
    }
  }

  onChange(event) {
    event.preventDefault()
    this.props.onChange(event)
    this.setState({ filled: event.target.value.length > 0 })
  }

  onFocus(event) {
    this.setState({ focus: true })
  }

  onBlur(event) {
    this.setState({ focus: false })
  }

  render() {
    const { placeholder, value, name, disabled, type, error, className } = this.props
    const focus_class = this.state.focus ? `is-focused` : ``
    const filled_class = this.state.filled ? `is-filled` : ``
    const error_pos = this.state.focus || this.state.filled ? `-3.5rem` : `-2.5rem`
    return (
      <div className={`form-group mt-3 ${focus_class} ${filled_class} ${className}`}>
        <label className="label-floating" htmlFor={name}>
          {placeholder}
        </label>
        <input
          className={`form-control `}
          type={type ? type : `text`}
          name={name}
          value={value}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          disabled={disabled}
          autoCapitalize="off"
          autoComplete="off"
          spellCheck="false"
          autoCorrect="off"
        />
        {error !== undefined && <p className={`error text-danger position-absolute`} style={{bottom: error_pos}}>{error}</p> }
      </div>
    )
  }
}

FloatingInput.defaultProps = {
  disabled: false,
  placeholder: `Enter Value`,
  value: ``,
  name: `floating`,
  type: `text`,
  className: ``,
}

export default FloatingInput
