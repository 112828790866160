import React from 'react'

const SvgShare = props => (
  <svg width={24} height={27} {...props}>
    <path
      fill="#F5F2ED"
      fillRule="evenodd"
      stroke="#F5F2ED"
      strokeWidth=".5"
      d="M198.108,37.6666667 L177.892,37.6666667 C177.399229,37.6666667 177,37.2622736 177,36.763199 L177,22.3145607 C177,21.8154861 177.399229,21.411093 177.892,21.411093 L182.351024,21.411093 C182.843795,21.411093 183.243024,21.8154861 183.243024,22.3145607 C183.243024,22.8125231 182.843795,23.2169162 182.351024,23.2169162 L178.78397,23.2169162 L178.78397,35.8609006 L197.216027,35.8609006 L197.216027,23.2169162 L193.054139,23.2169162 C192.561368,23.2169162 192.162139,22.8125231 192.162139,22.3145607 C192.162139,21.8154861 192.561368,21.411093 193.054139,21.411093 L198.108,21.411093 C198.600771,21.411093 199,21.8154861 199,22.3145607 L199,36.763199 C199,37.2622736 198.600771,37.6666667 198.108,37.6666667 Z M187.872734,30.5371289 C187.382136,30.5371289 186.983992,30.1360013 186.983992,29.6402633 L186.983992,15.4200586 L184.707193,18.2429388 C184.397161,18.6272833 183.836942,18.6863167 183.456204,18.3743922 C183.07438,18.0624563 183.015648,17.4976749 183.325666,17.1122182 L187.25697,12.238398 C187.448425,12.1425928 187.650769,12.0634994 187.855257,12 C188.128299,12.0690691 188.172892,12.0902355 188.41439,12.2049771 L192.396937,17.124427 C192.544879,17.3082376 192.614499,17.5444197 192.590567,17.7794696 C192.565548,18.0156374 192.449152,18.2317453 192.266388,18.3799276 C192.082546,18.5303174 191.848667,18.6005015 191.613686,18.5759927 C191.378718,18.5514838 191.163327,18.4333999 191.015399,18.2484856 L188.738599,15.4267177 L188.738599,29.6403631 C188.738599,30.1260625 188.354603,30.5237822 187.872693,30.5371289 L187.872734,30.5371289 Z"
      transform="translate(-176 -11)"
    />
  </svg>
)
export default SvgShare
